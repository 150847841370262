import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import TextArea from "../components/TextArea"
import Button from "../components/Button"
import { FiArrowRight } from "react-icons/fi"
import styled from "styled-components"
import {
  compose,
  color,
  space,
  typography,
  layout,
  textAlign,
  fontSize,
  fontWeight,
  flexbox,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
} from "styled-system"
import config from "../config"

const Box = styled("div")(
  {
    boxSizing: "border-box",
  },
  space,
  layout,
  flexbox,
  textAlign,
  fontWeight,
  fontSize,
  color
)

const Flex = styled("div")(
  {
    display: "flex",
  },
  space,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent
)

const Text = styled.span`
  ${textAlign}
  ${fontWeight}
  ${fontSize}
  ${space}
  ${color}
`

const Row = styled.div`
  ${space}
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
`
const StyledBox = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
`

const PaperBox = styled(Box)`
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`

const StyledLink = styled.a`
  color: #495057;
  text-decoration: none;
`

function isJson(str) {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
  return true
}

const IndexPage = props => {
  const [jsonForm, setJsonForm] = useState("")
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(false)
  const [jsonUrl, setJsonUrl] = useState(null)
  const sendJSON = () => {
    setJsonUrl(null)
    setLoading(true)
    setStatus(null)
    if (!isJson(jsonForm)) {
      setStatus("notValidJson")
      setLoading(false)
      return
    }
    const body = {
      json: jsonForm
    }
    fetch(`${config.apiUrl}/data`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        setLoading(false)
        return response.json()
      })
      .then(function(myJson) {
        setLoading(false)
        console.log(myJson)
        setJsonUrl(myJson.url)
      })
      .catch(error => {
        setLoading(false)
        console.error(error)
        alert("Something went wrong")
      })
  }

  const handleTextArea = e => {
    setStatus(null)
    setJsonForm(e.target.value)
  }
  return (
    <Layout>
      <SEO title="Serve your JSON" />

      <Box mb="2rem">
        <h2 style={{ fontSize: 20, textAlign: "center" }}>
          Host your JSON easily and use in your apps
        </h2>
        <Row>
          <StyledBox>
            <Box p={3} mb={2}>
              <Flex justifyContent="center" alignItems="center">
                <Text fontSize={14} fontWeight="900">
                  Paste your JSON
                </Text>
                <FiArrowRight />
              </Flex>
            </Box>
          </StyledBox>
          <StyledBox>
            <Box p={3} mb={2}>
              <Flex justifyContent="center" alignItems="center">
                <Text fontSize={14} fontWeight="900">
                  Click save
                </Text>{" "}
                <FiArrowRight />
              </Flex>
            </Box>
          </StyledBox>
          <StyledBox>
            <Box p={3} mb={2}>
              <Flex justifyContent="center" alignItems="center">
                <Text fontSize={14} fontWeight="900">
                  Get your JSON url
                </Text>
                <FiArrowRight />
              </Flex>
            </Box>
          </StyledBox>
        </Row>
        <div style={{ marginBottom: `1.45rem` }}>
          <TextArea
            rows={9}
            onChange={e => handleTextArea(e)}
            value={jsonForm}
          />
        </div>

        {jsonUrl !== null && (
          <PaperBox p={3} mb={4}>
            <StyledLink href={jsonUrl} target="_blank">
              {jsonUrl}
            </StyledLink>
          </PaperBox>
        )}
        {status === "notValidJson" && (
          <div style={{ marginBottom: `1.45rem` }}>
            <Text color="red">Not valid JSON</Text>
          </div>
        )}

        {!jsonUrl &&
        <div>
          <Button onClick={() => sendJSON()} loading={loading ? 1 : 0}>
            Save
          </Button>
        </div> }

      </Box>
      {/*<Box>*/}
      {/*  <Box textAlign="center" fontWeight="600" fontSize="1.5rem">*/}
      {/*    OR*/}
      {/*  </Box>*/}
      {/*</Box>*/}
    </Layout>
  )
}

export default IndexPage
